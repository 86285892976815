import React from "react";
import theme from "theme";
import { Theme, Image, Box, Text, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"about"} />
		<Helmet>
			<title>
				A propos de nous - A propos de CoreFlex Gym
			</title>
			<meta name={"description"} content={"CoreFlex Gym est un témoignage du pouvoir de transformation du fitness. "} />
			<meta property={"og:title"} content={"A propos de nous - A propos de CoreFlex Gym"} />
			<meta property={"og:description"} content={"CoreFlex Gym est un témoignage du pouvoir de transformation du fitness. "} />
			<meta property={"og:image"} content={"https://lyongourmetgold.com/images/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} />
			<meta name={"msapplication-TileImage"} content={"https://lyongourmetgold.com/images/free-fitness-gym-4616540-3819408.png"} />
		</Helmet>
		<Components.Header />
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://lyongourmetgold.com/images/3.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline1"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Notre philosophie
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					CoreFlex Gym est un témoignage du pouvoir de transformation du fitness. Il ne s'agit pas seulement des séances d'entraînement que vous faites, mais des victoires personnelles que vous remportez à chaque répétition. Notre philosophie est simple : offrir un espace qui inspire la croissance, la santé et l'équilibre.
				</Text>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-11">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				display="flex"
				width="50%"
				lg-width="100%"
				lg-align-items="center"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				align-items="flex-start"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
			>
				<Text
					sm-font="--headline3"
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					md-text-align="left"
				>
					L'histoire de CoreFlex
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					sm-margin="0px 0px 30px 0px"
					md-text-align="left"
				>
					Bien que CoreFlex Gym ne soit pas défini par ses origines, ses murs résonnent du dévouement d'innombrables personnes qui ont transformé leurs aspirations en réalité. L'histoire du gymnase est racontée à travers le succès de ses membres, chacun ajoutant à l'esprit collectif qui palpite dans l'espace.
				</Text>
			</Box>
			<Box
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
			>
				<Box
					width="100%"
					height="auto"
					padding="0px 0px 70% 0px"
					transition="transform 0.2s ease-in-out 0s"
					hover-transform="translateY(-10px)"
					overflow-x="hidden"
					overflow-y="hidden"
					position="relative"
					transform="translateY(0px)"
				>
					<Image
						position="absolute"
						width="100%"
						top="auto"
						left={0}
						src="https://lyongourmetgold.com/images/4.jpg"
						object-fit="cover"
						display="block"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
		</Section>
		<Section padding="90px 0 100px 0" quarkly-title="Price-17">
			<Text margin="0px 0 20px 0px" font="normal 900 48px/1.2 --fontFamily-serifGaramond" lg-margin="0px 0 20px 0px">
				Pourquoi notre philosophie résonne-t-elle ?
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 34px"
				md-grid-template-columns="1fr"
				md-grid-gap="35px 0"
			>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://lyongourmetgold.com/images/5.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						- Atmosphère inclusive : CoreFlex Gym est conçu pour être un second foyer pour toute personne déterminée à travailler sur elle-même, quel que soit le stade auquel elle se trouve dans son parcours de remise en forme.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://lyongourmetgold.com/images/6.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						- Approche holistique : Nous croyons qu'il faut développer non seulement la force physique, mais aussi la force mentale et le bien-être émotionnel par le biais de la remise en forme.
					</Text>
				</Box>
				<Box min-width="100px" min-height="100px">
					<Image
						src="https://lyongourmetgold.com/images/7.jpg"
						display="block"
						margin="0px 0px 25px 0px"
						width="100%"
						height="400px"
						object-fit="cover"
					/>
					<Text margin="0px 0px 15px 0px" font="normal 600 25px/1.2 --fontFamily-serifGaramond">
						- Un environnement dynamique : Notre espace en constante évolution garantit que votre entraînement n'est jamais monotone, mais toujours stimulant et excitant.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-10">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-padding="0px 0px 0px 0px"
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="50%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
			>
				<Box
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					transition="transform 0.2s ease-in-out 0s"
					padding="0px 0px 70% 0px"
					width="100%"
					height="auto"
					position="relative"
					hover-transform="translateY(-10px)"
				>
					<Image
						left={0}
						src="https://lyongourmetgold.com/images/8.jpg"
						object-fit="cover"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
						position="absolute"
						display="block"
					/>
				</Box>
			</Box>
			<Box
				flex-direction="column"
				lg-width="100%"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				sm-margin="0px 0px 0px 0px"
				lg-order="1"
				width="50%"
				align-items="flex-start"
				sm-padding="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				display="flex"
			>
				<Text
					margin="0px 0px 36px 0px"
					color="--darkL2"
					font="--headline2"
					lg-text-align="center"
					sm-font="--headline3"
					md-text-align="left"
				>
					Embrassez le voyage CoreFlex
				</Text>
				<Text
					margin="0px 0px 0px 0px"
					color="--darkL2"
					font="--base"
					lg-text-align="center"
					md-text-align="left"
				>
					Rejoignez la communauté CoreFlex et faites de votre programme d'entraînement une histoire de progrès, de résilience et de transformation. Chaque visite à notre salle de sport est un chapitre de votre récit continu d'amélioration de soi.
				</Text>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});